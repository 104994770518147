/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './static/fonts/fonts.css';
import wrapPageIntl from './src/utils/wrapPageIntl';

export const wrapPageElement = wrapPageIntl;
